import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import loadable from '@loadable/component';

import BareLayout from '../layouts/bareLayout';
import { isLoggedIn } from 'services/auth.api';
import { getSuggestedPlan } from 'services/suggestion.api';
import { createPet } from 'services/pets.api';
import {
  deleteAllPetsList,
  createPetCreationPayload,
  updateAllPetsList,
  getChickenBoxProductUrl,
  removeSpacesFromQueryParams,
  setInviteCode,
  applyInviteCodeVoucher,
  updateCheckoutState,
  deleteCheckoutState,
} from '../utils';
import { PackageSuggestion, Pet, DogsAgeType, OrderType } from 'models';
import Loader from '../components/common/Loader';
import {
  addLine,
  applyVoucherToBasket,
  createNewBasket,
  removeBasket,
  useAppDispatch,
} from 'state';
import { TitleComponent } from 'components/TitleComponent';

const PetSuggestion = loadable(() => import('components/PetSuggestion'));

interface EmailInfo {
  name: string;
  age: string;
  weight: string;
  activity: string;
  email: string;
  first_name: string;
  food_percent: string;
  discount_voucher: string;
}
export default ({ location }: { location: any }) => {
  const dispatch = useAppDispatch();

  const { dogsList = [], userData = {}, edit = false } = location.state
    ? location.state
    : {};

  if (isLoggedIn()) {
    navigate('/account/dashboard/');
  }

  const [showLoader, setShowLoader] = useState(false);
  const [petsList, setPetsList] = useState(dogsList);

  const initBasket = async () => {
    if (!petsList.length) {
      setPetsList([]);
      deleteAllPetsList();
      deleteCheckoutState();
      await dispatch(removeBasket());
      await dispatch(createNewBasket());
      await applyInviteCodeVoucher();
    }
  };

  const processExistingDogData = async () => {
    setShowLoader(true);
    const stringToProcess = location.search.substr(1, location.search.length);
    const paramArray = stringToProcess.split('&');
    const paramsData = paramArray?.reduce((prev: any, item: string) => {
      const [key, value] = item.split('=');
      prev[key] = value;
      return prev;
    }, {} as EmailInfo);

    const {
      name,
      age,
      weight,
      activity,
      food_percent,
      first_name,
      email,
      discount_voucher,
      invite_code,
      breed,
    } = paramsData;

    invite_code && setInviteCode(invite_code);

    if (
      name &&
      age &&
      weight &&
      activity &&
      food_percent &&
      first_name &&
      email
    ) {
      const petData = {
        dogsName: removeSpacesFromQueryParams(name),
        age: age,
        weight: weight,
        lifeStyle: activity,
        feedPercentage: food_percent || '100',
        breed: breed || '',
      };
      const userInfo = {
        userFirstName: removeSpacesFromQueryParams(first_name),
        email: email,
      };

      axios
        .all([
          getSuggestedPlan({
            age: petData.age,
            activity_level: petData.lifeStyle,
            dgwght: Number(petData.weight),
            food_percent: Number(petData.feedPercentage),
            breed: petData.breed,
          }),

          new Promise(resolve => {
            setTimeout(() => {
              return resolve();
            }, 10);
          }),
        ])
        .then(
          axios.spread(function(
            suggestedPlan: PackageSuggestion,
            createPetResponse: Pet,
          ) {
            const petCreationPayload: Pet = createPetCreationPayload(
              petData.dogsName,
              petData.age,
              petData.lifeStyle,
              petData.weight,
              DogsAgeType.YEARS,
              petData.age,
              petData.feedPercentage,
              petData.breed,
            );

            const updatedDogsList = [];
            updatedDogsList.push({
              petDetails: { ...petCreationPayload },
              suggestedPlan,
              suggestion: {},
            });
            updateAllPetsList(updatedDogsList);

            (async () => {
              await dispatch(removeBasket());
              await dispatch(createNewBasket());
              await applyInviteCodeVoucher();

              const updatedBasket = await dispatch(
                addLine({
                  url: getChickenBoxProductUrl(suggestedPlan?.product || null),
                  petId: createPetResponse.id,
                  frequency: suggestedPlan.frequency,
                  quantity: suggestedPlan.box_count,
                }),
              );

              if (!!discount_voucher) {
                await dispatch(applyVoucherToBasket(discount_voucher));
              }

              setShowLoader(false);
              setTimeout(() => {
                const checkoutState = {
                  boxCount: suggestedPlan.box_count,
                  frequency: suggestedPlan.frequency,
                  dogsName: petData.dogsName,
                  total: updatedBasket.total_incl_tax,
                  basketUrl: updatedBasket.url,
                  totalExcludingDiscounts:
                    updatedBasket.total_incl_tax_excl_discounts,
                  userFirstName: userInfo.userFirstName,
                  email: userInfo.email,
                  dogsList: updatedDogsList,
                };
                updateCheckoutState(checkoutState);
                navigate(`/checkout?ot=${OrderType.New}`, {
                  state: checkoutState,
                });
              }, 500);
            })();
          }),
        )
        .catch(e => {
          setShowLoader(false);
        });
    } else {
      if (!!discount_voucher) {
        try {
          await initBasket();
          await dispatch(applyVoucherToBasket(discount_voucher));
        } catch (error) {
          console.error('Error applying voucher', error);
        }
      }
      setShowLoader(false);
    }
  };
  useEffect(() => {
    if (location?.state?.referrer === '/feedingplan/') {
    } else {
      setPetsList([]);
      deleteAllPetsList();
    }
  }, [location]);

  return (
    <>
      <TitleComponent />
      <BareLayout>
        {showLoader && <Loader />}
        <PetSuggestion userData={userData} dogsList={petsList} edit={edit} />
      </BareLayout>
    </>
  );
};
